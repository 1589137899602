
































































































































































































































































































































































































import { Observer } from 'mobx-vue'
import moment from 'moment'
import { Component, Inject, Ref, Vue } from 'vue-property-decorator'
import { CreateVaultViewmodel } from '../../viewmodel/create-vault-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: CreateVaultViewmodel
  @Ref('launch-date-menu') launchDateMenu: any
  @Ref('launch-time-menu') launchTimeMenu: any
  launchDatePopUp = false
  launchTimePopUp = false
  dateMin = moment().format('YYYY-MM-DD')

  valid = false

  options = {
    chart: {
      type: 'area',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    theme: {
      mode: 'dark'
    },
    colors: ['#40cf78', '#3cc573', '#36b46a', '#288a56'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8
      }
    },
    legend: {
      show: false
    },
    xaxis: {
      type: 'datetime',
      categories: ['18/6/2022', '18/7/2022', '18/8/2022', '18/9/2022', '18/10/2022']
    }
  }
  series = [
    {
      name: 'South',
      data: this.generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
        min: 10,
        max: 60
      })
    },
    {
      name: 'North',
      data: this.generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
        min: 10,
        max: 20
      })
    },
    {
      name: 'Central',
      data: this.generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
        min: 10,
        max: 15
      })
    }
  ]
  generateDayWiseTimeSeries(baseval, count, yrange) {
    let i = 0
    const series: any[] = []
    while (i < count) {
      const x = baseval
      const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
      series.push([x, y])
      baseval += 86400000
      i++
    }
    return series
  }
}
